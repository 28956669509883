<template>
  <div class="status-cursor" v-if="mode === VIEW_MODE" @click="mode = EDIT_MODE">
    <span :class="pRescuer.status.color">
      {{ pRescuer.status.text }}
    </span>
  </div>
  <div v-else-if="mode === EDIT_MODE" class="d-flex flex-row align-center w-100">
    <RescueStatusSelect
      :pStatus="pRescuer.status"
      @onChangeStatus="onChangeStatus($event)"
    />
    <v-icon
      class="ml-2"
      color="red"
      @click="mode = VIEW_MODE"
    >
      mdi-close
    </v-icon>
  </div>
</template>

<script>
const VIEW_MODE = "VIEW";
const EDIT_MODE = "EDIT";

export default {
  name: "RescueStatus",

  components: {
    RescueStatusSelect: () => import("./RescueStatusSelect.vue"),
  },

  props: {
    pRescuer: Object,
  },

  data: () => ({
    VIEW_MODE,
    EDIT_MODE,

    mode: undefined,
  }),

  methods: {
    onChangeStatus(selectedStatus) {
      this.mode = VIEW_MODE;
      this.$emit("onSelectStatus", selectedStatus);
    },
  },

  mounted() {
    if ((this.pRescuer || {}).id) {
      this.mode = VIEW_MODE;
    } else {
      this.mode = EDIT_MODE;
    }
  },
};
</script>

<style scoped>
.status-cursor {
  cursor: pointer;
}
</style>
